var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "edit-form search_form",
          attrs: {
            model: _vm.editForm,
            "label-position": "right",
            "label-width": "130px",
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "排序 :", prop: "displayorder" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入排序", clearable: "" },
                model: {
                  value: _vm.editForm.displayorder,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "displayorder", $$v)
                  },
                  expression: "editForm.displayorder"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "幻灯片标题 :", prop: "name" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题", clearable: "" },
                model: {
                  value: _vm.editForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "name", $$v)
                  },
                  expression: "editForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "block-item", attrs: { label: "幻灯片图片 :" } },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadFile",
                  attrs: {
                    "list-type": "picture-card",
                    multiple: false,
                    limit: 1,
                    action: _vm.uploadOption.uploadUrl,
                    headers: _vm.uploadOption.uploadHeader,
                    name: _vm.uploadOption.uploadName,
                    fileList: _vm.uploadOption.fileList,
                    "on-success": _vm.handleUploadSu,
                    "on-error": _vm.handleError
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "幻灯片链接 :", prop: "url" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入链接", clearable: "" },
                model: {
                  value: _vm.editForm.url,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "url", $$v)
                  },
                  expression: "editForm.url"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "block-item", attrs: { label: "状态" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.editForm.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "enabled", $$v)
                  },
                  expression: "editForm.enabled"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "margin-top": "30px" }
        },
        [
          _vm.editStatus === "add"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: false, size: "medium" },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
          _vm.editStatus === "update"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: false, size: "medium" },
                  on: { click: _vm.handleUpdate }
                },
                [_vm._v("修改")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.goBack } },
            [_c("span", [_vm._v("取消")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }