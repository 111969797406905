import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=61c12fd0&scoped=true&"
import script from "./edit.vue?vue&type=script&lang=js&"
export * from "./edit.vue?vue&type=script&lang=js&"
import style0 from "./edit.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./edit.vue?vue&type=style&index=1&id=61c12fd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c12fd0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/paitony/Works/www/mx/charge_pile/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61c12fd0')) {
      api.createRecord('61c12fd0', component.options)
    } else {
      api.reload('61c12fd0', component.options)
    }
    module.hot.accept("./edit.vue?vue&type=template&id=61c12fd0&scoped=true&", function () {
      api.rerender('61c12fd0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/advert/wechat/slide/edit.vue"
export default component.exports